import React from 'react'
import styles from './sede_main_button.module.scss'
import greenBack from "./../../images/prado-min.jpg"
import redBack from "./../../images/grupo.min.png"
import config from '../../config'

const SedeMainButton = ({title, color, image}) => {
	/* let image;
	image = color ? (greenBack) : (redBack) */
	console.log(color)
	var gradient = color ? "rgba(22, 220, 106, 0.6)" : "rgba(205, 13, 20, 0.6)"
	var buttonImage = `${config.server}/assets/${image}`

	return (
		<div className={styles.mainButton} style={{'backgroundImage': `linear-gradient(${gradient}, ${gradient}), url(${buttonImage})`}}>
			<p className={styles.mainText}>{title}</p>
			<img alt="flecha" src={require('../../images/Flecha.svg')}/>
		</div>
	)
}
export default SedeMainButton
