import React, { useState } from 'react';
import styles from './carousel.module.scss'
import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";

const Carousel = ({ testimonios }) => {
	const handleOnDragStart = e => e.preventDefault()
	let TestMaker = ({name, test, image}) => {
		return (
			<div onDragStart={handleOnDragStart} className={styles.testimonioContainer}>
				<img src={image}/>
				<div className={styles.menData}>
					<p className={styles.testim}>{`"${test}"`}</p>
					<p className={styles.namePerson}>{name}</p>
				</div>
			</div>
		)
	}
	const [tests, setTests] = useState(testimonios.map((el) => ( TestMaker(el))))
	const responsive = {
	  0: { items: 1 },
	  800: { items : 2 },
	  1024: { items: 3 },
	  1500: { items: 4 }
	}

	const handleColor = (e) => {
		try{
			if(window.innerWidth < 800){
				document.querySelectorAll('#carouselContainer .alice-carousel__stage .alice-carousel__stage-item.__active')[0].children[0].style.background = "#DE892A";
				document.querySelectorAll('#carouselContainer .alice-carousel__stage .alice-carousel__stage-item.__active')[0].children[0].children[1].children[0].style.color = "#FFF";
				document.querySelectorAll('#carouselContainer .alice-carousel__stage .alice-carousel__stage-item.__active')[0].children[0].children[1].children[1].style.color = "#FFF";
			}else{
				document.querySelectorAll('#carouselContainer .alice-carousel__stage .alice-carousel__stage-item.__active')[1].children[0].style.background = "#DE892A";
				document.querySelectorAll('#carouselContainer .alice-carousel__stage .alice-carousel__stage-item.__active')[1].children[0].children[1].children[0].style.color = "#FFF";
				document.querySelectorAll('#carouselContainer .alice-carousel__stage .alice-carousel__stage-item.__active')[1].children[0].children[1].children[1].style.color = "#FFF";
			}
		}catch(e){

		}
	}

	const removeColor = (e) => {
		try{
			if(window.innerWidth < 800){
				console.log("entro")
				document.querySelectorAll('#carouselContainer .alice-carousel__stage .alice-carousel__stage-item.__active')[0].children[0].style.background = "#FFF";
				document.querySelectorAll('#carouselContainer .alice-carousel__stage .alice-carousel__stage-item.__active')[0].children[0].children[1].children[0].style.color = "#7F8299";
				document.querySelectorAll('#carouselContainer .alice-carousel__stage .alice-carousel__stage-item.__active')[0].children[0].children[1].children[1].style.color = "#23263E";
			}else{
				document.querySelectorAll('#carouselContainer .alice-carousel__stage .alice-carousel__stage-item.__active')[1].children[0].style.background = "#FFF";
				document.querySelectorAll('#carouselContainer .alice-carousel__stage .alice-carousel__stage-item.__active')[1].children[0].children[1].children[0].style.color = "#7F8299";
				document.querySelectorAll('#carouselContainer .alice-carousel__stage .alice-carousel__stage-item.__active')[1].children[0].children[1].children[1].style.color = "#23263E";
			}
		}catch(e){

		}
	}

	const initColor = (e) => {
		try{
			setTimeout(function(){
				if(window.innerWidth < 800){
					document.querySelectorAll('#carouselContainer .alice-carousel__stage')[0].getElementsByClassName('alice-carousel__stage-item __active')[0].children[0].style.background = "#DE892A"
					document.querySelectorAll('#carouselContainer .alice-carousel__stage')[0].getElementsByClassName('alice-carousel__stage-item __active')[0].children[0].children[1].children[0].style.color = "#FFF"
					document.querySelectorAll('#carouselContainer .alice-carousel__stage')[0].getElementsByClassName('alice-carousel__stage-item __active')[0].children[0].children[1].children[1].style.color = "#FFF"
				}else{
					document.querySelectorAll('#carouselContainer .alice-carousel__stage')[0].getElementsByClassName('alice-carousel__stage-item __active')[1].children[0].style.background = "#DE892A"
					document.querySelectorAll('#carouselContainer .alice-carousel__stage')[0].getElementsByClassName('alice-carousel__stage-item __active')[1].children[0].children[1].children[0].style.color = "#FFF"
					document.querySelectorAll('#carouselContainer .alice-carousel__stage')[0].getElementsByClassName('alice-carousel__stage-item __active')[1].children[0].children[1].children[1].style.color = "#FFF"
				}
			}, 1000)
		}catch(e){

		}
	}

	return (
		<div className={styles.container} id="carouselContainer">
			<AliceCarousel dotsDisabled buttonsDisabled autoPlay={true} duration={500} autoPlayInterval={7000} mouseDragEnabled responsive={responsive} items={tests} onInitialized={initColor} onSlideChanged={handleColor} onSlideChange={removeColor}>
			</AliceCarousel>
		</div>
	)
}

export default Carousel;
