import React from 'react'
import styles from './service_icon.module.scss'

const ServiceIcon = ({ title, description, image }) => {
	return(
		<div className={styles.container}>
			<img alt="icono" src={image}/>
			<div className={styles.serviceData}>
				<h4>{title}</h4>
				<p>{description}</p>
			</div>
		</div>
	)
}

export default ServiceIcon;
