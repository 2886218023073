import React from 'react'
import styles from './testimonios_section.module.scss'
import Carousel from '../Carousel'

const TestimoniosSection = () => {
	return (
		<div className={styles.container}>
			<h2>TESTIMONIOS</h2>
			<p>Conoce las opiniones de nuestros clientes y <br/> su experencia en Cowork entre todos.</p>
			<Carousel testimonios={testimonios}/>
		</div>
	)
}

const testimonios = [
	{
		name: 'Omaira Medina',
		test: 'Ser parte de Cowork entre todos, ha sido un elemento clave para la independencia de mi empresa, ha incrementado las oportundiades de negocios con el ecosistema de emprededores a mi alrededor.',
		/* image: require('./../../images/cowork_testimonio.png') */
	},
	{
		name: 'Marco de la Hoz',
		test: 'Increible experiencia en cowork! ¡Buen ambiente, y ni hablar de las salas de juntas! ¡Muchas gracias pora cogerme estos ultimos 2 meses! ¡Son los mejores!',
		/* image: require('./../../images/cowork_testimonio.png') */
	},
	{
		name: 'Juan Garcia',
		test: 'En cowork entre todos encontré un espacio agradable tiene un excelente ambiente. Buena atención, al menos para trabajar y compartir',
		/* image: require('./../../images/cowork_testimonio.png') */
	},
	{
		name: 'Esteban Soto',
		test: 'En cowork entre todos encontré un espacio agradable tiene un excelente ambiente. Buena atención, al menos para trabajar y compartir',
		/* image: require('./../../images/cowork_testimonio.png') */
	}
]

export default TestimoniosSection
