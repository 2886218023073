import React, {useState, useEffect} from "react"
import {Link} from "gatsby"
import Layout from "../components/Layout"
import MainCardReserva from "../components/MainCardReserva"
import SpaceSection from "../components/SpaceSection"
import ServiceSection from "../components/ServiceSection"
import TestimoniosSection from "../components/TestimoniosSection"
import PartnerSection from "../components/PartnerSection"
import SedeMainButton from "../components/SedeMainButton"
import ScheduleSection from "../components/ScheduleSection"
import SEO from "../components/seo"
import {navigate} from 'gatsby'
import {useSelector, useDispatch} from 'react-redux'
import styles from './styles/index.module.scss'
import FreeTour from '../components/FreeTour'
import config from '../config'
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Lottie from 'react-lottie';
import { makeStyles } from '@material-ui/core/styles';
import { Helmet } from "react-helmet";

//import 'typeface-roboto';

const useStyles = makeStyles(theme => ({
	root: {
	  flexGrow: 1,
	},
	paper: {
	  padding: theme.spacing(2),
	  textAlign: 'center',
	  color: theme.palette.text.secondary,
	},
  }));

const IndexPage = () => {
	const classes = useStyles();
	const [headQuarters, setHeadQuarters] = useState()
	const [typeOffice, setTypeOffice] = useState()
	const [events, setEvents] = useState()
	const dispatch = useDispatch()

	const getHeadQuarters = async () => {
		try {
			const response = await fetch(`${config.server}/getAllHeadQuarters`, {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json'
				}
			})
			const fetchData = await response.json()
			console.log("SEDES", response)
			setHeadQuarters(fetchData)
		} catch (e) {
			console.log(e)
		}
	}

	const getTypeOffices = async () => {
		try {
			const response = await fetch(`${config.server}/office_types`, {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json'
				}
			})
			const fetchData = await response.json()
			let newArr = [];
			setTypeOffice([])
			fetchData.map((el,idx)=>{
				if(el.feature_image_uid == null){
					el.feature_image_uid = 'tipo_placeholder.png'
				}
				newArr.push(el);
				if(idx == fetchData.length-1){
					setTypeOffice(newArr)
				}
			})
			//setTypeOffice(fetchData)
		} catch (e) {
			console.log(e)
		}
	}

	const getEvents = async () => {
		try {
			const response = await fetch(`${config.server}/getAllEvents`, {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json'
				}
			})
			const fetchData = await response.json()
			console.log("eventos", response)
			fetchData.map((el, idx) => fetchData[idx].date = new Date(el.date))
			setEvents(fetchData)
		} catch (e) {
			console.log(e)
		}
	}

	useEffect(() => {
		getHeadQuarters()
		getTypeOffices()
		getEvents()
	}, []);

	const defaultOptions = {
		loop: true,
		autoplay: true,
		animationData: require('./../animations/3678-fluid-loading-animation.json'),
		rendererSettings: {
			preserveAspectRatio: 'xMidYMid slice'
		}
	};

	const handleFilterSpace = (name) => {
		dispatch({type: 'filter/SET_ACTUAL_TYPE_OFFICE', data: name})
		navigate('/filter')
	}

	return (<div>{
			typeof headQuarters !== 'undefined' && typeof typeOffice !== 'undefined' && typeof events !== 'undefined'
				? (<Layout>
					<SEO title="Inicio"/>
					{/* <div className={classes.root}>
						<Grid container spacing={3}>
							<Grid item xs={12}>
							<MainCardReserva headquarters={headQuarters}/>
							</Grid>
							
							
						</Grid>
					</div> */}
					
						<Grid container spacing={0}
						className={styles.imageFeatured}>
							<Grid item xs={12} md = {6}>
								<MainCardReserva headquarters={headQuarters}/>
							</Grid>
						</Grid>

					<div className={styles.sedesButtons}>
						{
							headQuarters.map((el, idx) => {
								console.log("seds", el)
								return (
									<Grid item xs={12} md = {5}>										<Link to={`/sedes/${el.slug}`} className={styles.linkStyles} style={{textDecoration: "none"}}>
											<div key={idx} className={styles.sedeButton}>
											<SedeMainButton image = {el.feature_image_uid} title={el.name} color={idx % 2
														? (false)
														: (true)}/>
											</div>
										</Link>
									</Grid>)
							})
						}
					</div>
					<SpaceSection uid={true} spaces={typeOffice} handleParentSpace={handleFilterSpace}/>
					<ServiceSection/>
					<TestimoniosSection/>
					<ScheduleSection schedules={events}/>
					<PartnerSection/>
					<FreeTour/>
					<Helmet>
						<script src="https://storage.googleapis.com/coworkfile/whasapp.js"/>
						<script src="https://storage.googleapis.com/coworkfile/CDN_screen_capture.js"/>
					</Helmet>
				</Layout>)
				: (<Lottie options={defaultOptions} height={300} width={300} style={{
						position: 'absolute',
						left: '50%',
						transform: 'translateX(-50%) translateY(-50%)',
						top: '50%'
					}}/>)
		}</div>)
}

export default IndexPage
