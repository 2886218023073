import React from 'react'
import styles from './service_section.module.scss'
import ServiceIcon from '../ServiceIcon'
import { navigate } from 'gatsby'
import { Button } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

const ServiceSection = () =>{

	const handleNavigate = () => {
		console.log("entro")
		navigate('/contact')
	}
	return(
		<div className={styles.container}>
 			<h2>BENEFICIOS</h2>
			<p>Todos los servicios y la infraestructura que necesitas <br/> para que seas eficiente en tu trabajo.</p>
			<div className={styles.servicesContainer}>
				{
					services.map((el, idx)=>(
						<Grid item xs={12} md={4}>
							<div key={idx} className={styles.serviceElement}>
								<ServiceIcon title={el.title} description={el.description} image={el.image}/>
							</div>
						</Grid>
					))
				}
			</div>
			<Button variant="outlined" className={styles.buttonContact} onClick={handleNavigate}>
				CONTÁCTANOS
			</Button>
		</div>
	)
}

const services = [
	{
		title: 'Internet Veloz',
		description: 'Tenga acceso ilimitado a internet de alta vélocidad y calidad.',
		image: require('./../../images/Wifi.svg')
	},
	{
		title: 'Limpieza',
		description: 'Mantenemos un ambiente saludable y limpio.',
		image: require('./../../images/Limpio.svg')
	},
	{
		title: 'Salas de Reunión',
		description: 'Atiende a tus clientes en un ambiente cómodo, profesional y organizado (Video beam).',
		image: require('./../../images/Maleta.svg')
	},
	{
		title: 'Servicio de Recepción',
		description: 'Tenemos personal adecuado para atender a sus clientes e invitados.',
		image: require('./../../images/Servicio.svg')
	},
	{
		title: 'Servicio Postal',
		description: 'Recibe toda tu correspondencia en Cowork entre todos.',
		image: require('./../../images/Postal.svg')
	},
	{
		title: 'Café y Bebidas',
		description: 'Un cafecito siempre es bueno para las ideas y negocios',
		image: require('./../../images/Cafe.svg')
	},
	{
		title: 'Descanso',
		description: 'En Cowork también tendrás un lugar cómodo para descansar.',
		image: require('./../../images/Sillon.svg')
	},
	{
		title: 'Impresoras Empresariales',
		description: 'Contamos con servicio de impresión dependiendo del paquete que elijas.',
		image: require('./../../images/Impresora.svg')
	},
	{
		title: 'Cocineta',
		description: 'En este espacio encontrarás nevera, micro-ondas y más.',
		image: require('./../../images/cocineta.svg')
	},
	{
		title: 'Lounge Area',
		description: 'Tendrás disponible la zona común para descansar o interactuar con los demás.',
		image: require('./../../images/lounge.svg')
	},
	{
		title: 'Eventos',
		description: 'Nuestro calendario de eventos siempre está en movimiento!',
		image: require('./../../images/eventos.svg')
	},
	{
		title: 'Dirección Empresarial',
		description: 'Ofrecemos una dirección que puedes brindar a tus clientes e invitados.',
		image: require('./../../images/direccion.svg')
	}
]

export default ServiceSection;
