import React, {useState, useEffect, useRef} from 'react'
import styles from './schedule_box.module.scss'
import { makeStyles } from '@material-ui/core/styles';
import { Button, Snackbar, SnackbarContent, Dialog, DialogTitle,
	DialogContent, List, Paper, Typography, ListItem, ListItemText, Avatar, ListItemAvatar, TextField } from '@material-ui/core'
	
const useStyles = makeStyles(theme => ({
	root: {
		padding: theme.spacing(3, 2),
	},
	}));

const ScheduleBox = ({ date, title, type, color, sede }) => {
	const classes = useStyles();
	const [openDialogResumen, setOpenDialogResumen] = useState(false)
	const backColor = color === "#16DC6A" ? ('#FFF'):('transparent')

	const handleCloseDialogResumen = () => {
		setOpenDialogResumen(!openDialogResumen)
	}

	var maxLengthTitle = 47;
	var maxLengthSede = 47;
	var maxLengthType = 33;

	return(
		<div className={styles.container} style={{backgroundColor: color, 
		 cursor: "pointer"}}
		 onClick = {handleCloseDialogResumen}>
			{/* <div>{JSON.stringify(date)}</div> */}
			<div className={styles.dataContainer}>
				<p className={styles.date}>{date}</p>
				<p className={styles.title}>{title.length >= maxLengthTitle ? title.substring(0, maxLengthTitle) + '...': title }</p>
				<p className={styles.sede}>{sede.length >= maxLengthSede ? sede.substring(0, maxLengthSede) + '...': sede }</p>
			</div>
			<div className={styles.event_type}>
				<p className={styles.type}>{type.length >= maxLengthType ? type.substring(0, maxLengthType) + '...': type}</p>
				<div style={{backgroundColor: backColor}} className={styles.dot}></div>
			</div>
			<Dialog onClose={handleCloseDialogResumen} 
					disableEscapeKeyDown
					PaperProps={{ style: {margin: '5px'} }}
					open={openDialogResumen} style={{maxHeigth:'100%', 
					margin: "0"}}
					>
					<DialogTitle style={{textAlign:'center'}}>Resumen de Evento</DialogTitle>
				      <DialogContent>
					  <Paper className={classes.root}>
							<Typography component="p">
								<b>Fecha de Evento:</b> {date}
							</Typography>
							<Typography  component="p">
								<b>Tipo:</b> {type}
							</Typography>
							<Typography component="p">
								<b>Sede:</b> {sede}
							</Typography>
							<Typography component="p">
								 <b>Descripción:</b> {title}
							</Typography>
						</Paper>
						<DialogContent className={styles.calendarDialogContent}>
						  <Button onClick={handleCloseDialogResumen} variant="contained" className={styles.buttonClose}>
							  Cerrar
						  </Button>
						  </DialogContent>
					  </DialogContent>
				    </Dialog>
		</div>
	)
}


export default ScheduleBox
