import React, {useState} from 'react'
import { Card, CardContent, TextField, MenuItem, Button, Snackbar, SnackbarContent} from '@material-ui/core';
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import styles from './main_card_reserva.module.scss'
import {navigate} from 'gatsby'
import { makeStyles } from '@material-ui/core/styles';
import { Link } from "gatsby"
import {IoIosWarning} from "react-icons/io";
import {useSelector, useDispatch} from 'react-redux'
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(theme => ({
	root: {
	  flexGrow: 1,	  
	},
	paper: {	 
	  padding: theme.spacing(2),
	  textAlign: 'center',
	  color: theme.palette.text.secondary,
	},
  }));

const MainCardReserva = ({headquarters}) => {
	const classes = useStyles({
		Card: {
		  borderRadius: '10px',	  
		},
	  }
	);

	const dispatch = useDispatch()
	const [openSnack,setOpenSnack] = useState(false)
	const [reserva, setReserva] = useState({
		sede: ''
	});

	const handleChange = name => event => {
	  	setReserva({ ...reserva, [name]: event.target.value });
	};
	console.log('Reserva',reserva)
	const handleFilter = () => {
		if(/^\s*$/.test(reserva.sede)){
			setOpenSnack(true)
		}else{
			dispatch({type: 'filter/SET_ACTUAL_FILTER_HEADQUARTER_AND_PERSON', data: reserva})
			navigate('/filter')
		}
	}

	const SnackBarWrapper = ({message}) => {
		return(
			<Snackbar
				anchorOrigin={{
				  vertical: 'bottom',
				  horizontal: 'left',
				}}
				open={openSnack}
				autoHideDuration={6000}
				onClose={()=>setOpenSnack(!openSnack)}
			  >
				<SnackbarContent
					style={{backgroundColor: '#d32f2f'}}
					  message={
						<span className={styles.errorMessage}>
							{
								<IoIosWarning size={20} className={styles.iconError}/>
							}
							<span>{message}</span>
						</span>
					  }
					  onClose={()=>setOpenSnack(!openSnack)}
					  variant="error"
				/>
			</Snackbar>
		)
	}

	return (
<>
		
		<div className={styles.containerMainCard} >
			<Card className={styles.mainCard} style={{borderRadius:'10px'}}>
				<CardContent className={styles.contentCard}>
					<div className={styles.info}>
						<h2 style={{fontWeight: "700", lineHeight: "40px"}}>Oficinas entre todos</h2>
						<p className={styles.description}>Explora nuestros espacios basado en tus necesidades</p>
					</div>
					<div className={styles.mainForm} >
						{/* <ThemeProvider theme={theme} >
							<TextField
								id="cantidadPersonas"
								select
								value={reserva.person}
								onChange={handleChange('person')}
								label="Cantidad de personas"
								className={styles.textField}
								SelectProps={{
								    MenuProps: {
									  className: styles.menu,
									},
								
								}}
								margin="normal"
								variant="outlined"
								borderRadius="50px"
								>
									<MenuItem value="= 1">1</MenuItem>
									<MenuItem value="= 2">2</MenuItem>
									<MenuItem value="= 3">3</MenuItem>
									<MenuItem value="= 4">4</MenuItem>
									<MenuItem value=">= +5">+5</MenuItem>
							</TextField>
						</ThemeProvider> */}
						<ThemeProvider theme={theme}>
							<TextField
								id="elegirSede"
								select
								value={reserva.sede}
								onChange={handleChange('sede')}
								label="Elegir Sede"
								className={styles.textField}
								SelectProps={{
								    MenuProps: {
									  className: styles.menu,
								    },
								}}
								margin="normal"
								variant="outlined"
								>
									{
										headquarters.map((el,idx)=><MenuItem key={idx} value={el.name}>{el.name}</MenuItem>)
									}
							</TextField>
						</ThemeProvider>
						<Link className="text-dark" to="/filter">
							<Button variant="contained" /* onClick={handleFilter} */ className={styles.buttonFormSend} style={{borderRadius:'10px'}}>
								BUSCAR
							</Button>
						</Link>
					</div>
				</CardContent>
			</Card>
			<SnackBarWrapper message='Faltan datos por llenar'/>
		</div>
		</>
	)
}

const theme = createMuiTheme({
	overrides: {
      MuiOutlinedInput: {
          root: {
			  
              position: 'relative',
			  color:'#000',
              '& $notchedOutline': {
				  borderColor: 'rgba(0,0,0,0.23)',
				  borderRadius: '10px',
              },
              '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
                  borderColor: 'rgba(0,0,0,0.23)',
                  // Reset on touch devices, it doesn't add specificity
                  '@media (hover: none)': {
                      borderColor: 'rgba(0,0,0,0.23)',
                  },
              },
              '&$focused $notchedOutline': {
                  borderColor: '#DE892A',
                  borderWidth: 1,
              },
          },
      },
      MuiFormLabel: {
          root: {
			  color:'rgba(0,0,0,0.54)',
              '&$focused': {
                  color: '#DE892A'
              }
          }
      }}
});

export default MainCardReserva
