import React from 'react'
import styles from './space_section.module.scss'
import SpaceBox from '../SpaceBox'
import config from './../../config'
import Grid from '@material-ui/core/Grid';


const SpaceSection = ({uid, spaces, handleParentSpace}) => {
	const handleFilterSpace = (name) => {
		handleParentSpace(name)
	}
	return(
		<div className={styles.spaceType}>
			<h2>TIPOS DE ESPACIOS</h2>			 
			<p style={{paddingBottom: "3rem"}}>Explora los diferentes tipos de espacios disponibles y <br/> descubre cuál es el más adecuado para tu negocio o emprendimiento.</p>
			<div className={styles.containerSpace}>
			<Grid container spacing={1}>

				{
					spaces.map((el,idx)=>(
						<Grid item xs={12} md={3}>
							<div onClick={() => handleFilterSpace(el.name)} key={idx} className={styles.spaceBox}>
								<SpaceBox title={el.name} image={`${config.assets}/${uid ? el.feature_image_uid: el.feature_image}`} />
							</div>
						</Grid>
					))
				}
				</Grid>
			</div>

		</div>
	)
}

export default SpaceSection
