import React from 'react'
import styles from './space_box.module.scss'

const SpaceBox = ({title, image}) =>{

	return (
		<div className={styles.container}>
			<img alt="imagen-espacio" src={image}/>
			<h3>{title}</h3>
		</div>
	)

}

export default SpaceBox;
