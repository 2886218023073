import React from 'react'
import styles from './free_tour.module.scss'
import { Button } from '@material-ui/core';
import { navigate } from 'gatsby'
import Grid from '@material-ui/core/Grid';


const FreeTour = () => {
	return (
		<div className={styles.visit}>
			<Grid container spacing={1}>
			<Grid item xs={12} md = {8}>
			<div className={styles.freeVisit}>
				<h2>Gratis, agenda una visita guiada</h2>
				<p>Conoce nuestras instalaciones en persona, sin ningun costo.</p>
			</div>
			</Grid>
			<Grid item xs={12} md = {4}
			style = {{
				display: "flex",
				justifyContent: "center"
			}}>
			<Button variant="contained" className={styles.buttonTour} onClick={()=>navigate('/contact')}>
				+ Agenda un Tour
			</Button>
			</Grid>
			</Grid>
		</div>
	)
}

export default FreeTour
