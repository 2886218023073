import React from 'react'
import styles from './schedule_section.module.scss'
import ScheduleBox from '../ScheduleBox'
import { Button } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import moment from 'moment'
import 'moment/locale/es' 


const ScheduleSection = ({schedules}) => {
	return(
		<div className={styles.container}>
			<h2> NUESTRA AGENDA </h2>
			{/* <div className={styles.containerSchedule}> */}
		      <Grid container spacing={1}>
				{
					schedules.map((el,idx)=>{
						const color = idx == 0 ? ('#16DC6A'):(idx==1 ? ('#CD0D14'):('#AFB2C4'))
						if(typeof el.date === 'string'){
							schedules[idx].date = new Date(el.date)
						}
						//const formattedMonth = ("0" + el.date.getDate()).slice(-2);

						const day = moment.utc(el.date).format('LL')
						//const dateFormatted = `${day} ${monthNames[el.date.getMonth()]}`
						return(
							<Grid item xs={12} sm = {6} md={4}>
								{/* <div key={idx} className={styles.schedule}> */}
									<ScheduleBox date={day} title={el.event_name} type={el.event_type} color={color} sede={el.head_quarter_name}/>
								{/* </div> */}
							</Grid>
						)
					})
				}
				</Grid>
			{/* </div> */}
			{/* <div className={styles.buttonCalendarContainer}> */}
				{/*<Button variant="outlined" className={styles.buttonCalendar}>
					Ver Calendario
				</Button>*/}
			{/* </div> */}
		</div>
	)
}

export default ScheduleSection
