import React from 'react'
import styles from './partner_section.module.scss'
import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles(theme => ({
	root: {
	  flexGrow: 1
	},
	paper: {
	  padding: theme.spacing(2),
	  textAlign: 'center',
	  color: theme.palette.text.secondary,
	},
	img:{
		width: "150px"
	}
  }));

const PartnerSection = () => {
	const classes = useStyles();

	return (
		<div className={styles.container}>
			<h2>Compañias con las que hemos trabajado</h2>
			<p>Algunos de los equipos y empresas que hacen parte de cowork entre todos.</p>
			<div className={classes.root}>
			<Grid container spacing={3}>
				<Grid item xs={6} md = {3}>
					<img className = {classes.img} src={require('./../../images/marykay_company.svg')}/>
				</Grid>
				<Grid item xs={6} md = {3}>
					<img className = {classes.img} src={require('./../../images/rapido_company.svg')}/>
				</Grid>
				<Grid item xs={6} md = {3}>
					<img className = {classes.img} src={require('./../../images/cpc_company.svg')}/>
				</Grid>
				<Grid item xs={6} md = {3}>
					<img className = {classes.img} src={require('./../../images/habla_company.svg')}/>
				</Grid>
			</Grid>
			</div>
			{/* <div className={styles.partnerContainer}>
				<img src={require('./../../images/marykay_company.svg')}/>
				<img src={require('./../../images/rapido_company.svg')}/>
				<img src={require('./../../images/cpc_company.svg')}/>
				<img src={require('./../../images/habla_company.svg')}/>
			</div> */}
		</div>
	)
}

export default PartnerSection
